import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpHeaders,
  HttpEvent,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Utility } from './utility';
@Injectable()
export class CustomHttpInterceptors implements HttpInterceptor {
  constructor(private utility: Utility) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Apply the headers
    const userDetails = JSON.parse(this.utility.getValue('UserDetails'))
    const codeDetails = JSON.parse(this.utility.getValue('CodeDetails'))
    // tslint:disable-next-line: no-string-literal
    if ((userDetails && userDetails['token']) || (codeDetails && codeDetails['token'])) {
      // tslint:disable-next-line: no-string-literal
      if(userDetails){
        const token ='token ' + userDetails['token'];
        const headers = new HttpHeaders().set('Authorization', token);
        const authRequest = req.clone({ headers });
        return next.handle(authRequest);
      }
      else{
        const token ='token ' + codeDetails['token'];
        const headers = new HttpHeaders().set('Authorization', token)
        const authRequest = req.clone({ headers });
        return next.handle(authRequest);
      }
    }
    else {
      return next.handle(req);
    }
  }
}
