/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
class AuthenticationService extends __BaseService {
  static readonly authenticationAwsIdentityPath = '/api/authentication/awsIdentity';
  static readonly authenticationChangePasswordPath = '/api/authentication/change_password';
  static readonly authenticationCreateAdminAccountPath = '/api/authentication/create_admin_account';
  static readonly authenticationForgetPasswordPath = '/api/authentication/forget_password';
  static readonly authenticationLoginPath = '/api/authentication/login';
  static readonly authenticationLogoutPath = '/api/authentication/logout';
  static readonly authenticationResetPasswordPath = '/api/authentication/reset_password';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Used by: CMS APP
   *
   * Used by: CMS APP
   * @param params The `AuthenticationService.AuthenticationAwsIdentityParams` containing the following parameters:
   *
   * - `Authorization`: Authorization
   *
   * - `Accept-language`: language
   */
  authenticationAwsIdentityResponse(params: AuthenticationService.AuthenticationAwsIdentityParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.AcceptLanguage != null) __headers = __headers.set('Accept-language', params.AcceptLanguage.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/authentication/awsIdentity`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Used by: CMS APP
   *
   * Used by: CMS APP
   * @param params The `AuthenticationService.AuthenticationAwsIdentityParams` containing the following parameters:
   *
   * - `Authorization`: Authorization
   *
   * - `Accept-language`: language
   */
  authenticationAwsIdentity(params: AuthenticationService.AuthenticationAwsIdentityParams): __Observable<null> {
    return this.authenticationAwsIdentityResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Used by: CMS APP
   *
   * Used by: CMS APP
   * @param params The `AuthenticationService.AuthenticationChangePasswordParams` containing the following parameters:
   *
   * - `data`:
   *
   * - `Authorization`: Authorization
   *
   * - `Accept-language`: language
   */
  authenticationChangePasswordResponse(params: AuthenticationService.AuthenticationChangePasswordParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.data;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.AcceptLanguage != null) __headers = __headers.set('Accept-language', params.AcceptLanguage.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/authentication/change_password`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Used by: CMS APP
   *
   * Used by: CMS APP
   * @param params The `AuthenticationService.AuthenticationChangePasswordParams` containing the following parameters:
   *
   * - `data`:
   *
   * - `Authorization`: Authorization
   *
   * - `Accept-language`: language
   */
  authenticationChangePassword(params: AuthenticationService.AuthenticationChangePasswordParams): __Observable<null> {
    return this.authenticationChangePasswordResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Used by: CMS APP
   *
   * Used by: CMS APP
   * @param params The `AuthenticationService.AuthenticationCreateAdminAccountParams` containing the following parameters:
   *
   * - `password_create_token`: password create token
   *
   * - `data`:
   *
   * - `Authorization`: Authorization
   *
   * - `Accept-language`: language
   */
  authenticationCreateAdminAccountResponse(params: AuthenticationService.AuthenticationCreateAdminAccountParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.passwordCreateToken != null) __params = __params.set('password_create_token', params.passwordCreateToken.toString());
    __body = params.data;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.AcceptLanguage != null) __headers = __headers.set('Accept-language', params.AcceptLanguage.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/authentication/create_admin_account`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Used by: CMS APP
   *
   * Used by: CMS APP
   * @param params The `AuthenticationService.AuthenticationCreateAdminAccountParams` containing the following parameters:
   *
   * - `password_create_token`: password create token
   *
   * - `data`:
   *
   * - `Authorization`: Authorization
   *
   * - `Accept-language`: language
   */
  authenticationCreateAdminAccount(params: AuthenticationService.AuthenticationCreateAdminAccountParams): __Observable<null> {
    return this.authenticationCreateAdminAccountResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Used by: CMS APP
   *
   * Used by: CMS APP
   * @param params The `AuthenticationService.AuthenticationForgetPasswordParams` containing the following parameters:
   *
   * - `data`:
   *
   * - `Authorization`: Authorization
   *
   * - `Accept-language`: language
   */
  authenticationForgetPasswordResponse(params: AuthenticationService.AuthenticationForgetPasswordParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.data;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.AcceptLanguage != null) __headers = __headers.set('Accept-language', params.AcceptLanguage.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/authentication/forget_password`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Used by: CMS APP
   *
   * Used by: CMS APP
   * @param params The `AuthenticationService.AuthenticationForgetPasswordParams` containing the following parameters:
   *
   * - `data`:
   *
   * - `Authorization`: Authorization
   *
   * - `Accept-language`: language
   */
  authenticationForgetPassword(params: AuthenticationService.AuthenticationForgetPasswordParams): __Observable<null> {
    return this.authenticationForgetPasswordResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Used by: CMS APP
   *
   * Used by: CMS APP
   * @param params The `AuthenticationService.AuthenticationLoginParams` containing the following parameters:
   *
   * - `data`:
   *
   * - `Authorization`: Authorization
   *
   * - `Accept-language`: language
   */
  authenticationLoginResponse(params: AuthenticationService.AuthenticationLoginParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.data;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.AcceptLanguage != null) __headers = __headers.set('Accept-language', params.AcceptLanguage.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/authentication/login`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Used by: CMS APP
   *
   * Used by: CMS APP
   * @param params The `AuthenticationService.AuthenticationLoginParams` containing the following parameters:
   *
   * - `data`:
   *
   * - `Authorization`: Authorization
   *
   * - `Accept-language`: language
   */
  authenticationLogin(params: AuthenticationService.AuthenticationLoginParams): __Observable<null> {
    return this.authenticationLoginResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Used by: CMS APP
   *
   * Used by: CMS APP
   * @param params The `AuthenticationService.AuthenticationLogoutParams` containing the following parameters:
   *
   * - `Authorization`: Authorization
   *
   * - `Accept-language`: language
   */
  authenticationLogoutResponse(params: AuthenticationService.AuthenticationLogoutParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.AcceptLanguage != null) __headers = __headers.set('Accept-language', params.AcceptLanguage.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/authentication/logout`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Used by: CMS APP
   *
   * Used by: CMS APP
   * @param params The `AuthenticationService.AuthenticationLogoutParams` containing the following parameters:
   *
   * - `Authorization`: Authorization
   *
   * - `Accept-language`: language
   */
  authenticationLogout(params: AuthenticationService.AuthenticationLogoutParams): __Observable<null> {
    return this.authenticationLogoutResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Used by: CMS APP
   *
   * Used by: CMS APP
   * @param params The `AuthenticationService.AuthenticationResetPasswordParams` containing the following parameters:
   *
   * - `password_reset_token`: password reset token
   *
   * - `data`:
   *
   * - `Authorization`: Authorization
   *
   * - `Accept-language`: language
   */
  authenticationResetPasswordResponse(params: AuthenticationService.AuthenticationResetPasswordParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.passwordResetToken != null) __params = __params.set('password_reset_token', params.passwordResetToken.toString());
    __body = params.data;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    if (params.AcceptLanguage != null) __headers = __headers.set('Accept-language', params.AcceptLanguage.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/authentication/reset_password`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Used by: CMS APP
   *
   * Used by: CMS APP
   * @param params The `AuthenticationService.AuthenticationResetPasswordParams` containing the following parameters:
   *
   * - `password_reset_token`: password reset token
   *
   * - `data`:
   *
   * - `Authorization`: Authorization
   *
   * - `Accept-language`: language
   */
  authenticationResetPassword(params: AuthenticationService.AuthenticationResetPasswordParams): __Observable<null> {
    return this.authenticationResetPasswordResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module AuthenticationService {

  /**
   * Parameters for authenticationAwsIdentity
   */
  export interface AuthenticationAwsIdentityParams {

    /**
     * Authorization
     */
    Authorization?: string;

    /**
     * language
     */
    AcceptLanguage?: string;
  }

  /**
   * Parameters for authenticationChangePassword
   */
  export interface AuthenticationChangePasswordParams {
    data?: {email: string, old_password: string, new_password: string};

    /**
     * Authorization
     */
    Authorization?: string;

    /**
     * language
     */
    AcceptLanguage?: string;
  }

  /**
   * Parameters for authenticationCreateAdminAccount
   */
  export interface AuthenticationCreateAdminAccountParams {

    /**
     * password create token
     */
    passwordCreateToken: string;
    data?: {password: string, confirm_password: string};

    /**
     * Authorization
     */
    Authorization?: string;

    /**
     * language
     */
    AcceptLanguage?: string;
  }

  /**
   * Parameters for authenticationForgetPassword
   */
  export interface AuthenticationForgetPasswordParams {
    data?: {email: string};

    /**
     * Authorization
     */
    Authorization?: string;

    /**
     * language
     */
    AcceptLanguage?: string;
  }

  /**
   * Parameters for authenticationLogin
   */
  export interface AuthenticationLoginParams {
    data?: {email: string, password: string, transaction_id?: string, device_type?: string, is_dataportal?: boolean};

    /**
     * Authorization
     */
    Authorization?: string;

    /**
     * language
     */
    AcceptLanguage?: string;
  }

  /**
   * Parameters for authenticationLogout
   */
  export interface AuthenticationLogoutParams {

    /**
     * Authorization
     */
    Authorization?: string;

    /**
     * language
     */
    AcceptLanguage?: string;
  }

  /**
   * Parameters for authenticationResetPassword
   */
  export interface AuthenticationResetPasswordParams {

    /**
     * password reset token
     */
    passwordResetToken: string;
    data?: {password: string, confirm_password: string};

    /**
     * Authorization
     */
    Authorization?: string;

    /**
     * language
     */
    AcceptLanguage?: string;
  }
}

export { AuthenticationService }
