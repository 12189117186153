import { UserDetails } from './../../components/auth/login/login.model';
import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from '../../../common/swagger-provider/services/authentication.service';
import { Utility } from 'src/common/utility';
import { ErrorHandlerComponent } from 'src/common/error-handler.component';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [ErrorHandlerComponent]
})
export class HeaderComponent implements OnInit {
  public errorMessage: string;
  public currentRoute: string;
  public userDetails: UserDetails
  constructor(
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private route: Router,
    private authenticationService: AuthenticationService,
    private utility: Utility,
    private errorHandler: ErrorHandlerComponent

  ) {
  }

  ngOnInit() {
    this.userDetails = JSON.parse(this.utility.getValue('UserDetails'));
  }

  getcurrentRoute(): string{
    this.currentRoute = this.route.url;
    return this.currentRoute
  }
  logout() {
    this.authenticationService.authenticationLogout({}).subscribe(
      res => {
        const resData: any = res;
        if (resData) {
          this.utility.deleteCookie('token');
          this.utility.removeValue('UserDetails');
          this.toastr.success(resData.message);
          this.route.navigate(['/']);
        }
      },
      e => {
        this.spinner.hide();
        this.errorMessage = this.errorHandler.handleError(e);
      }
    );
  }

}
