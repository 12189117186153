import { ErrorHandlerComponent } from './../common/error-handler.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Provider, forwardRef } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
// Application imports
import { AppComponent } from './app.component';
import { TypographyComponent } from './components/typography/typography.component';
import { DialogsComponent } from './common/dialogs/dialogs.component';
import { CustomHttpInterceptors } from 'src/common/http-interceptor';
import { Utility } from 'src/common/utility';
import { LayoutModule } from './layout/layout.module';
// 3rd Party Imports
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { SharedModule } from './shared/shared.module';

export const API_INTERCEPTOR_PROVIDER: Provider = {
  provide: HTTP_INTERCEPTORS,
  useExisting: forwardRef(() => CustomHttpInterceptors),
  multi: true,
};

@NgModule({
  declarations: [
    AppComponent,
    TypographyComponent,
    DialogsComponent,
  ],
  imports: [
    RouterModule.forRoot([]),
    BrowserModule,
    NgxDaterangepickerMd.forRoot(),
    ToastrModule.forRoot(),
    BrowserAnimationsModule,
    HttpClientModule,
    NgxDatatableModule,
    LayoutModule,
    NgxSpinnerModule,
    SharedModule
  ],
  providers: [Utility, CustomHttpInterceptors, API_INTERCEPTOR_PROVIDER],
  bootstrap: [AppComponent]
})
export class AppModule { }
