import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import * as moment from 'moment';
import 'moment-timezone';
@Injectable()
export class Utility {
  private behave = new BehaviorSubject<object>(Object);

  public info: any;
  public years: any = [];

  constructor() { }
  setValue = (key, value) => {
    localStorage.setItem(key, value);
  };
  getValue = key => {
    return localStorage.getItem(key);
  };
  removeValue = key => {
    return localStorage.removeItem(key);
  };
  clearAll = () => {
    localStorage.clearAll();
  };

  /**
   * This method is used to set cookie.
   */
  setCookie = (name: string, val: string) => {
    const value = val;
    document.cookie = name + '=' + value + '; path=/';
  };

  /**
   * @param name This method is used to get cookie value.
   */
  getCookie(name: string) {
    const value = '; ' + document.cookie;
    const parts = value.split('; ' + name + '=');
    if (parts.length === 2) {
      return parts
        .pop()
        .split(';')
        .shift();
    }
  }

  /**
   * @param name This method is used to delete cookie.
   */
  deleteCookie(name: string) {
    const date = new Date();
    date.setTime(date.getTime() + -1 * 24 * 60 * 60 * 1000);
    document.cookie =
    name + '=; expires = ' + date.toUTCString() + '; path= / ';
  }

  /**
   * This method is used to get indexed value of string/path.
   */
  getPathIndexedString = (data, fromValue, toValue) => {
    return data.substring(fromValue, toValue);
  };

  openModal(id: string) {
    const popup = document.getElementById(id);
    if (popup) {
      popup.classList.add('open');
    }
  }

  closeModal(id: string) {
    const popup = document.getElementById(id);
    if (popup) {
      popup.classList.remove('open');
    }
  }

  setData(tempData: any) {
    this.info = tempData;
  }

  getData() {
    return this.info;
  }

  removeData() {
    this.info = {};
  }

  convertTimeZone(timeZone: number, time: string) {
    const timeZoneList = [
      { timeZoneStatus: 1, offset: 'America/New_York', zone: 'EST' },
      { timeZoneStatus: 2, offset: 'CET', zone: 'CET' },
      { timeZoneStatus: 3, offset: 'UTC', zone: 'GMT' },
      { timeZoneStatus: 4, offset: 'Asia/Kolkata', zone: 'IST' },
    ];
    let zone: string;
    timeZoneList.forEach(elem => {
      if (timeZone === elem.timeZoneStatus) {
        time = moment
          .utc(time)
          .tz(elem.offset)
          .format('YYYY-MM-DD HH:mm:ss');
        zone = elem.zone;
      }
    });
    return { time, zone };
  }
  /**
   *
   * @param event This method is used to enter only number in input field.
   * @param type For Price user can enter float number like 45.99.
   */
  numberOnly(event: any, type: string): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (type !== 'id') {
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        event.keyCode !== 46
      ) {
        return false;
      }
      return true;
    } else {
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        return false;
      }
      return true;
    }
  }

  /**
   * This method is used to get month list.
   */
  getMonthList() {
    const monthList = [
      { name: 'January', id: '01' },
      { name: 'February', id: '02' },
      { name: 'March', id: '03' },
      { name: 'April', id: '04' },
      { name: 'May', id: '05' },
      { name: 'June', id: '06' },
      { name: 'July', id: '07' },
      { name: 'August', id: '08' },
      { name: 'September', id: '09' },
      { name: 'October', id: '10' },
      { name: 'November', id: '11' },
      { name: 'December', id: '12' },
    ];
    return monthList;
  }

  /**
   * This method is use to fetch next 30 years.
   */
  getLastYears() {
    this.years = [];
    const d = new Date();
    const n = d.getFullYear();
    for (let i = 0; i <= 30; i++) {
      const next = n + i;
      this.years.push(next);
    }
    return this.years;
  }

  setBehaviorView(behave: any) {
    this.behave.next(behave);
  }
  getBehaviorView(): Observable<any> {
    return this.behave.asObservable();
  }

  /**
   * This method is used to get the index.
   */
  getPageIndex(currentPage, lastPage) {
    const tempData = [];
    if (currentPage < 8) {
      for (let i = 1; i <= parseInt(lastPage, 10); i++) {
        tempData.push(i);
      }
    } else {
      for (let i = currentPage - 6; i < currentPage + 1; i++) {
        tempData.push(i);
      }
    }
    return tempData;
  }
}
