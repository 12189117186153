import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { Utility } from '../common/utility';
@Injectable({
  providedIn: 'root',
})
export class AuthGuardService implements CanActivate {
  constructor(private router: Router, private utility: Utility) {}
  canActivate(): // state: ActivatedRouteSnapshot,
  // route: RouterStateSnapshot
  Observable<boolean> | boolean {
    const loggedInUser = JSON.parse(this.utility.getValue('UserDetails'))
    // tslint:disable-next-line: no-string-literal
    if (loggedInUser && loggedInUser['token']) {
      return true;
    } else {
      this.router.navigate(['/login']);
      return false;
    }
  }
}


