import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaginationComponent } from './pagination/pagination.component';
import { QuillComponent } from './quill/quill.component';
import { QuillModule } from 'ngx-quill'


@NgModule({
  declarations: [PaginationComponent, QuillComponent],
  exports:[PaginationComponent, QuillComponent],
  imports: [
    CommonModule,
    QuillModule.forRoot(),
  ]
})
export class SharedModule { }
