import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
@Component({
  selector: 'app-error-handler',
  template: '',
})
export class ErrorHandlerComponent implements OnInit {
  constructor(private toastr: ToastrService, private route: Router) {}

  ngOnInit() {}

  handleError = (e): string => {
    if (e && e.status === 401) {
      // this.toastr.warning(e.error.message);
      this.removeLocalStorageDataOnUnauthorisedError();
      return e.error.message
    } else if (e && e.status === 400) {
      var errMsg = '';
      if(typeof e.error.message === 'object'){
        errMsg = e.error.message[0]
      }
      else{
        errMsg = e.error.message
      }
      // this.toastr.warning(errMsg.message[0]);
      return errMsg
    } else if (e && e.status === 403) {
      this.route.navigate(['/dashboard']);
      // this.toastr.warning(e.error.message);
      var errMsg = '';
      if(typeof e.error.message === 'object'){
        errMsg = e.error.message[0]
      }
      else{
        errMsg = e.error.message
      }
      return errMsg
    } else if (e && e.status === 404) {
      var errMsg = '';
      if(typeof e.error.message === 'object'){
        errMsg = e.error.message[0]
      }
      else{
        errMsg = e.error.message
      }
      // this.toastr.error(e.error.message);
      return errMsg
    } else if (e && e.status === 410) {
      var errMsg = '';
      if(typeof e.error.message === 'object'){
        errMsg = e.error.message[0]
      }
      else{
        errMsg = e.error.message
      }
      // this.toastr.error(e.error.message);
      return errMsg
    }
    else if (e && e.status === 500) {
      this.toastr.error('Problem processing request');
      return 'Problem processing request'
    } else {
      this.toastr.error('Error');
    }
  };

  /**
   * This method is used to clear localStorage based on condition for unauthorised errors.
   */
  removeLocalStorageDataOnUnauthorisedError = (): void => {
    localStorage.clear();
    this.route.navigate(['/login']);
  };
}
