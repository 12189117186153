import { UserDetails } from './../../components/auth/login/login.model';
import { Component, OnInit } from '@angular/core';
import { Utility } from 'src/common/utility';

@Component({
  selector: 'app-wrongroute',
  templateUrl: './wrongroute.component.html',
  styleUrls: ['./wrongroute.component.scss']
})
export class WrongrouteComponent implements OnInit {
  public codeDetails;
  constructor(private utility: Utility,) {
    this.codeDetails = JSON.parse(this.utility.getValue('CodeDetails'));
  }

  ngOnInit(): void {
  }

}
