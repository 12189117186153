import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HeaderComponent } from './header/header.component';
import {  AuthGuardService } from 'src/guards/authguard.service';
import { TypographyComponent } from '../components/typography/typography.component';
import { QuillComponent } from '../shared/quill/quill.component';
import { WrongrouteComponent } from './wrongroute/wrongroute.component';
const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'typo',
    component: TypographyComponent,
  },
  {
    path: 'quill',
    component: QuillComponent,
  },
  {
    path: '',
    loadChildren: () => import('../components/auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: '',
    component: HeaderComponent,
    children: [
      {
        path: 'users',
        loadChildren: () => import('../components/users/users.module').then(mod => mod.UsersModule),
        canActivate: [AuthGuardService]
      },
      {
        path: 'organizations',
        loadChildren: () => import('../components/organizations/organizations.module').then(mod => mod.OrganizationsModule),
        canActivate: [AuthGuardService]
      },
      {
        path: 'packs',
        loadChildren: () => import('../components/packs/packs.module').then(mod => mod.PacksModule),
        canActivate: [AuthGuardService]
      },
      {
        path: 'racusers',
        loadChildren: () => import('../components/racusers/racusers.module').then(mod => mod.RacusersModule),
        canActivate: [AuthGuardService]
      },
      {
        path: 'admins',
        loadChildren: () => import('../components/admin/admin.module').then(mod => mod.AdminModule),
        canActivate: [AuthGuardService]
      },
      // /Wild Card Route
      // { path: '**', pathMatch  : 'full', component: WrongrouteComponent},
    ]
  },
  // /Wild Card Route
  { path: '**', pathMatch  : 'full', component: WrongrouteComponent},

]
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LayoutRoutingModule { }
